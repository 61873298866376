import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { StyledContainer, Product, ProductContent } from "./styles"

SwiperCore.use([Navigation, Pagination])

const ProductsArmariosRoupeiros = () => {
  const data = useStaticQuery(graphql`
    query {
      noimage: file(relativePath: { eq: "products/noimage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armariopamultiuso2: file(
        relativePath: { eq: "products/armario-pa-multiuso2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armariopamultiuso: file(
        relativePath: { eq: "products/armario-pa-multiuso.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armario17: file(
        relativePath: { eq: "products/armario-modelo-17-75.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armario90: file(
        relativePath: { eq: "products/armario-modelo-PA-90.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armariopa1m: file(relativePath: { eq: "products/armario-pa1m.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armariopa1m2: file(relativePath: { eq: "products/armario-pa1m2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armariopa1m3: file(relativePath: { eq: "products/armario-pa1m3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp1porta: file(relativePath: { eq: "products/grp-1porta.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp2p: file(relativePath: { eq: "products/grp-2p.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp2p2: file(relativePath: { eq: "products/grp-2p2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp2ins2p: file(relativePath: { eq: "products/grp-2ins-2p.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp24: file(relativePath: { eq: "products/grp2-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp44p: file(relativePath: { eq: "products/grp4-4p.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp23: file(relativePath: { eq: "products/grp2-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp25: file(relativePath: { eq: "products/grp25.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp28: file(relativePath: { eq: "products/grp28.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp46: file(relativePath: { eq: "products/grp46.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp410: file(relativePath: { eq: "products/grp410.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp416: file(relativePath: { eq: "products/grp416.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp69: file(relativePath: { eq: "products/grp69.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp615: file(relativePath: { eq: "products/grp615.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp624: file(relativePath: { eq: "products/grp624.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp812: file(relativePath: { eq: "products/grp812.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp242: file(relativePath: { eq: "products/grp24.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp28123: file(relativePath: { eq: "products/grp28-123.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp26: file(relativePath: { eq: "products/grp26.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp412: file(relativePath: { eq: "products/grp412.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp621: file(relativePath: { eq: "products/grp621.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lavanderia1p: file(relativePath: { eq: "products/lavanderia1p.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lavanderia1p2: file(relativePath: { eq: "products/lavanderia1p2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp4ins4: file(relativePath: { eq: "products/grp4ins4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp4inssap4: file(relativePath: { eq: "products/grp4ins-sap4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp48: file(relativePath: { eq: "products/grp48.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp66: file(relativePath: { eq: "products/grp66.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp88: file(relativePath: { eq: "products/grp88.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp1212: file(relativePath: { eq: "products/grp1212.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp1616: file(relativePath: { eq: "products/grp1616.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp2020: file(relativePath: { eq: "products/grp2020.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grp32: file(relativePath: { eq: "products/grp32.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledContainer as="section">
      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.armariopamultiuso2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.armariopamultiuso.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Armário modelo P.A Multiuso 01 porta</h3>

          <ul>
            <li>Cód.: P.A Multiuso</li>
            <li>Cor: Cinza</li>
            <li>Fechadura: com chave</li>
            <li>3 bandejas internas</li>
            <li>Altura: 170 cm </li>
            <li>Largura: 48 cm</li>
            <li>Profundidade: 40 cm</li>
          </ul>

          {/* <p className="item-price">
            R$ 570,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.armario17.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Armário modelo 17/75 e 17/90</h3>

          <ul>
            <li>Cód.: AE 17/75</li>
            <li>Cor Cinza</li>
            <li>Fechadura: com chave</li>
            <li>3 bandejas internas</li>
            <li>Altura: 170 cm </li>
            <li>Largura: 75 cm</li>
            <li>Profundidade: 33 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
            <br />
            <li>Cód.: AE 17/90</li>
            <li>Cor Cinza</li>
            <li>Fechadura: com chave</li>
            <li>3 bandejas internas</li>
            <li>Altura: 170 cm </li>
            <li>Largura: 90 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 776,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.armario90.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Armário modelo P.A 90 e P.A 120</h3>

          <ul>
            <li>Cód.: P.A 90</li>
            <li>Cor Cinza</li>
            <li>Fechadura: com chave</li>
            <li>4 bandejas internas</li>
            <li>Altura: 198 cm </li>
            <li>Largura: 90 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
            <br />
            <li>Cód.: P.A 120</li>
            <li>Cor Cinza</li>
            <li>Fechadura: com chave</li>
            <li>4 bandejas internas</li>
            <li>Altura: 198 cm </li>
            <li>Largura: 120 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 991,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.armariopa1m2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.armariopa1m3.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.armariopa1m.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Armário modelo P.A 1 metro</h3>

          <ul>
            <li>Com chave</li>
            <li>Altura: 100 cm</li>
            <li>Largura: 90 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 635,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.lavanderia1p2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.lavanderia1p.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Armário Lavanderia - 01 Porta</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 50 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 642,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp1porta.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 1/1,2 - 01 Porta</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 33 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 522,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp2p.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.grp2p2.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 2 - 02 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 33 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 581,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp2ins2p.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 2/INS - 02 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 63 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 734,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp23.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 2/3 - 03 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 33 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 603,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp24.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 2/4 - 04 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 33 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 603,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp44p.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 4 - 04 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 63 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 759,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp44p.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 4 - 1,2 - 04 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 63 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 890,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp4ins4.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 4 INS - 04 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 100 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.016,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp4inssap4.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 4 INS/SAP - 04 Portas c/ porta sapatos</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 100 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.058,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp48.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 4/8 - 08 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 63 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 817,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp25.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 2/5 - 05 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 33 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 624,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp26.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 2/6 - 06 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 33 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 638,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.noimage.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 2/7 - 07 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 33 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 659,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp28.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 2/8 - 08 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 33 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 666,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp46.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 4/6 - 06 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 63 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 797,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp410.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 4/10 - 10 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 63 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 840,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp412.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 4/12 - 12 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 63 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 870,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.noimage.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 4/14 - 14 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 63 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 891,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp416.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 4/16 - 16 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 63 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 903,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp66.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 6/6 - 06 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 93 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 954,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp88.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 8 - 08 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 123 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.143,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp69.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 6/9 - 09 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 93 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 992,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp1212.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 12 - 12 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 93 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.013,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp615.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 6/15 - 15 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 93 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.076,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp1616.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 16 - 16 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 123 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.238,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.noimage.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 6/18 - 18 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 93 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.060,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp2020.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 20 - 20 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 123 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.300,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp621.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 6/21 - 21 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 93 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.165,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp624.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 6/24 - 24 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 93 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.203,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp812.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 8/12 - 12 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 123 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.186,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp242.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 24 - 24 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 123 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.355,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp28123.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 28 - 28 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 123 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.407,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.grp32.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>GRP 32 - 32 Portas</h3>

          <ul>
            <li>Altura: 198 cm </li>
            <li>Largura: 123 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>Chapa 26 </li>
            <li>Chapa 24 </li>
            <li>Chapa 22 </li>
          </ul>

          {/* <p className="item-price">
            R$ 1.459,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>
    </StyledContainer>
  )
}

export default ProductsArmariosRoupeiros

import React from "react"

import SEO from "../components/seo"

import ProductsArmariosRoupeiros from "../components/products/products-armarios-roupeiros"

const Arquivos = () => (
  <>
    <SEO title="Armários Roupeiros" />

    <ProductsArmariosRoupeiros />
  </>
)

export default Arquivos
